import { materialListService, supplierService } from '@/api/';
import { TagsViewModule } from '@/store/modules/tags-view';
import { messageError, translation } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import { PublicApproveStatusEnum, ResourceStatusEnum } from '@/resource/enum';
import { MaterialDetails, SupplierResource } from '@/resource/model';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'DetailsMaterial',
  components: {}
})
export default class DetailsMaterial extends Vue {
  /**
   *  仓库ID
   */
  public id = Number(this.$route.query.id);

  public readonly: boolean = true;
  public disabled: boolean = true;

  public form: MaterialDetails | null = null;
  public suppliers: Array<SupplierResource> = [];
  public attributeOptions: Array<{ label: string; value: string }> = [];
  public dateOptions: Array<{ label: string; value: string }> = [
    {
      label: '日',
      value: 'DAY'
    },
    {
      label: '月',
      value: 'MONTH'
    },
    {
      label: '年',
      value: 'YEAR'
    }
  ];

  private get getApproveStatusI18Key(): string {
    return translation('publicApproveStatus.' + PublicApproveStatusEnum[this.form!.approveStatus]);
  }
  private get getStatusI18Key(): string {
    let res;
    switch (this.form!.enableStatus!) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return translation(res);
  }

  /**
   * 查询可用供应商数据
   */
  public async initSuppliers(): Promise<void> {
    try {
      this.suppliers = await supplierService.listEnable();
    } catch (error) {
      messageError(error);
    }
  }
  public getAttributeList(): void {
    try {
      this.attributeOptions = materialListService.getAttributeList();
    } catch (error) {
      messageError(error);
    }
  }

  public activated(): void {
    // 当id变化时，重新加载数据
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.initSuppliers();
      this.getAttributeList();
      this.getDetails(this.id);
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.initSuppliers();
      this.getAttributeList();
      this.getDetails(this.id);
    }
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    TagsViewModule.DelView(this.$route);
    next();
  }
  private getDetails(id: number): void {
    materialListService
      .getById(id)
      .then(res => {
        // Object.assign(this.form, res);
        this.form = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
